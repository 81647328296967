import compromiso from "../assets/images/valores/Compromiso.jpg";
import conocimiento from "../assets/images/valores/Conocimiento.jpg";
import solucionar from "../assets/images/valores/Solucionar.jpg";
import colaboracion from "../assets/images/valores/Colaboracion.jpg";
import transparencia from "../assets/images/valores/Transparencia.jpg";
import pasion from "../assets/images/valores/Pasion.jpg";

export const valoresData = [
  {
    titulo:"Compromiso",
    valor: `Siempre comprometidos con el <strong>análisis</strong> cuidadoso y detallado de los minerales que procesamos para cuantificar e identificar los compuestos de una muestra materia de estudio.`,
    idValor: 1,
    src:`${compromiso}`,
  },
  {
    titulo:"Compartir el conocimiento",
    valor: "<strong>Concentramos</strong> y compartimos el conocimiento más avanzado de la ingeniería química y mantenemos como prioridad capacitar y actualizar a nuestros integrantes.",
    idValor: 2,
    src:`${conocimiento}`,
  },
  {
    titulo:"Solucionar",
    valor: "<strong>Fragmentamos</strong> los problemas hasta que la solución es evidente y nuestros clientes tienen la respuesta a sus procesos. Buscamos y entregamos respuestas.",
    idValor: 3,
    src:`${solucionar}`,
  },
  {
    titulo:"Colaboración",
    valor: "<strong>Aglomeramos</strong> en una misma esfera a especialistas de primer nivel, equipo de última tecnología y procesos innovadores que colaboran en conjunto para poder cumplir con las altas expectativas de nuestros clientes.",
    idValor: 4,
    src:`${colaboracion}`,
  },
  {
    titulo:"Transparencia",
    valor: "<strong>Filtramos</strong> todas las impurezas para lograr la transparencia que exigen y merecen nuestros clientes.",
    idValor: 5,
    src:`${transparencia}`,
  },
  {
    titulo:"Pasión",
    valor: "Colocamos la pasión, el coraje, la honestidad y el respeto de todos nuestros colaboradores en un crisol, lo <strong>fundimos</strong> y obtenemos una empresa fuerte, capaz y responsable.",
    idValor: 6,
    src:`${pasion}`,
  },
];