import { ICarrera } from "../models/interfaces/ICarrera";

export const carreraData: ICarrera[] = [
  {
    idCarrera: "metalurgico",
    label: "Ingeniero Químico Metalúrgico",
  },
  {
    idCarrera: "quimico",
    label: "Ingeniero Químico",
  },
  {
    idCarrera: "bioquimico",
    label: "Ingeniero Bioquímico",
  },
];
