import React from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link as MaterialLink} from "@mui/material";

import { ROUTES } from "../../constants/routes";
import logoLab from "../../assets/images/logoLabHeader.png";
import dia from "../../assets/images/dia.svg";
import noche from "../../assets/images/noche.svg";

import "../../styles/components/Header.scss";

interface HeaderProps {
  modoNoche: boolean;
  setModoNoche: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Header({ modoNoche, setModoNoche }: HeaderProps) {
  const toggleModo = () => {
    setModoNoche(!modoNoche);
  };

  return (
    <Navbar
      className="navmenu scroll px-3"
      expand="md"
      variant="dark"
      sticky="top"
      collapseOnSelect
    >
      <Container fluid>
        <Navbar.Brand as={Link} to={ROUTES.ROOT}>
          <img src={logoLab} className='logo' alt="Logo laboratorio" height="100%" width="100%"/>
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-end">
          <Nav className="navmenu__nav-items">
            <Nav.Link as={NavLink} to={ROUTES.ROOT}>
              Inicio
            </Nav.Link>
            <Nav.Link as={NavLink} to={ROUTES.TEAMWORK}>
              Personal
            </Nav.Link>
            <Nav.Link as={NavLink} to={ROUTES.INVESTIGACION}>
              Producto
            </Nav.Link>
            <Nav.Link as={NavLink} to={ROUTES.OFERTA}>
              Oferta de trabajo
            </Nav.Link>
            <NavDropdown  title="Política y Alcance del SGC" id="basic-nav-dropdown">
                <NavDropdown.Item href={`${process.env.PUBLIC_URL}/Política_Alcance_Files/ASGC.pdf`}
                target="_blank" rel="noopener noreferrer" className="text-wrap"> <MaterialLink>Alcance del Sistema de Gestión de Calidad.pdf</MaterialLink></NavDropdown.Item>
                <NavDropdown.Item href={`${process.env.PUBLIC_URL}/Política_Alcance_Files/PdeC.pdf`}
                target="_blank" rel="noopener noreferrer"><MaterialLink>Política de Calidad.pdf</MaterialLink></NavDropdown.Item>
              </NavDropdown>
            <button onClick={toggleModo} className='nav-link'>
              {modoNoche ? (
                <Image src={noche} alt="noche" width="100%" height="100%"/>
              ) : (
                <Image src={dia} alt="dia" width="100%" height="100%"/>
              )}
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
