export const ROUTES = {
  ROOT: "/",
  HOME: "/home",
  TEAMWORK: "/TeamWork",
  INVESTIGACION: "/Investigacion",
  OFERTA: "/Oferta",
  CONTACTO: "/Contacto",
  SERVICIOS: "/Servicios",
};

export const ANCLAS = {
  MAPA: "https://goo.gl/maps/FRwXTCRgxkHpkash6",
  SERVICIOS: "/#servicios",
  EQUIPOS: "/#equipos",
};
