import React from "react";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Header from "./components/Header/Header";
import HeaderMain from "./components/Header/HeaderMain";
import Footer from "./components/Footer/Footer";
import WhatsApp from "./components/WhatsApp/WhatsApp";
import Loading from "./components/Loading/Loading";
import Home from "./pages/Home/Home";
import TeamWork from "./pages/TeamWork/TeamWork";
import Oferta from "./pages/Oferta/Oferta";
import Investigacion from "./pages/Investigacion/Investigacion";

import { ROUTES } from "./constants/routes";
import Servicios from "./pages/Servicios/Servicio";

import "./App.css";

function App() {
  const [cargar, setCargar] = useState<boolean>(false);
  const [rock, setRock] = useState<string>("");
  const [render, setRender] = useState<any>(null);
  const [modoNoche, setModoNoche] = useState(false);
  let location = useLocation();

  useEffect(() => {
    setCargar(true);
    setRender(location.pathname !== ROUTES.ROOT ? <Header modoNoche={modoNoche} setModoNoche={setModoNoche}/> : <HeaderMain modoNoche={modoNoche} setModoNoche={setModoNoche}/>);
    setRock(
      modoNoche ? (location.pathname === ROUTES.OFERTA  ? "backgroundRockNoche" : "") : (location.pathname === ROUTES.OFERTA  ? "backgroundRock" : "")
    );
    setTimeout(() => {
      setCargar(false);
    }, 500);
  }, [location.pathname, modoNoche]);

  const fondo = () => {
    return {
      minHeight: "100vh",
      backgroundColor: `${modoNoche ? '#000' : '#fff'}`
    };
  };

  return (
    <>
      {cargar && <Loading />}
      <main
        className="d-flex flex-column justify-content-between"
        style={fondo()}
      >
        {render}
        <div className={`flex-grow-1 ${rock}`}>
            <Routes>
              <Route path={ROUTES.ROOT} element={<Home modoNoche={modoNoche}/>} />
              <Route path={ROUTES.TEAMWORK} element={<TeamWork modoNoche={modoNoche}/>} />
              <Route path={ROUTES.OFERTA} element={<Oferta modoNoche={modoNoche}/>} />
              <Route path={ROUTES.INVESTIGACION} element={<Investigacion modoNoche={modoNoche}/>} />

              <Route path={ROUTES.SERVICIOS} element={<Servicios modoNoche={modoNoche}/>} />
            </Routes>
        </div>
        <WhatsApp />
        <Footer />
      </main>
    </>
  );
}

export default App;
