import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../TitleIzquierda";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function InfoAglobriq() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);  

  return (
    <motion.div 
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}>    
      <Title titulo="¿Para qué sirve?" scroll={inView}/>
      <Container className="pt-5 pb-5">
        <Row className="infoAglobriq">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="textInfo">
            <Card.Text className="text-justify">
              Es un aditivo que está integrado por una mezcla de partículas finas de un potenciador químico que se mezcla con solventes y pigmentos que permiten que se pueda mezclar de manera efectiva. Donde todas las partículas corresponden a minerales naturales sin riesgo a la salud o a las personas.
            </Card.Text>
            <Card.Text className="text-justify">
              El aglomerante "AgloBriq" fue diseñado para mejorar las características físicas y químicas de la briqueta de HRD. Su base principal consiste en silicato de sodio cuyas propiedades son potenciadas mediante un aditivo diseñado para mejorar su habilidad de proporcionar una fijación rápida y firme entre las partículas que son compactadas.
            </Card.Text>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}