import React, { useEffect, useRef, useState } from 'react';
import { NavLink, } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "@mui/material";
import { Container, Image, Navbar, Nav } from "react-bootstrap";

import { ANCLAS, ROUTES } from "../../constants/routes";

import video from "../../assets/videos/prueba.mp4";
import logo from "../../assets/images/logoLabHeader.png";
import logoLab from "../../assets/images/logoLab.svg";
import dia from "../../assets/images/dia.svg";
import noche from "../../assets/images/noche.svg";

import "../../styles/components/HeaderMain.scss";

interface HeaderMainProps {
  modoNoche: boolean;
  setModoNoche: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HeaderMain({ modoNoche, setModoNoche }: HeaderMainProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    setNavbar(window.scrollY >= 50);
  };

  const toggleModo = () => {
    setModoNoche(!modoNoche);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    let position = 0;
  
    const drawImage = (image, position) => {
      if (ctx && canvas) {
        // Configurar el tamaño del canvas
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        // Calcular las coordenadas y tamaño de la imagen
        const scale = Math.min(canvas.width / image.width, canvas.height / image.height) / 2;
        const imageWidth = image.width * scale;
        const imageHeight = image.height * scale;
        const x = position * (canvas.width - imageWidth) /1.04;
        const y = (canvas.height - imageHeight) / 1.05;
  
        // Dibujar un fondo en el lienzo
        ctx.fillStyle = '#2E3A4D';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        // Dibujar la imagen en el lienzo
        ctx.drawImage(image, x, y, imageWidth, imageHeight);
      }
    };
  
    // Cargar la imagen que deseas dibujar
    const image = document.createElement('img');
    image.src = `${logoLab}`; // Reemplaza con la ruta de tu imagen
  
    // animacion de transicion de la imagen
    image.onload = () => {        
      const animate = () => {
        position += 0.01; // Ajusta la velocidad de avance de la imagen
  
        drawImage(image, position);
  
        if (position < 1) {
          requestAnimationFrame(animate);
        }
      };
  
      animate();
    };

    const handleResize = () => {
      // Volver a dibujar la imagen cuando cambie el tamaño de la pantalla
      drawImage(image, position);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <>
      <Navbar
        className={`navmenu ${navbar ? "scroll" : ""} px-3`}
        expand="lg"
        variant="dark"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid>
          <NavLink to={ROUTES.ROOT}>
            <Image
              src={logo}
              className='logo'
              alt="Logo laboratorio"
              height="100%"
              width="100%"
            />
          </NavLink>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navmenu__nav-items">
              <Nav.Link as={NavLink} to={ROUTES.ROOT}>
                Inicio
              </Nav.Link>
              <Link
                href={ANCLAS.SERVICIOS}
                color="inherit"
                className='nav-link servicio'
              >
                Servicios
              </Link>
              <Link
                href={ANCLAS.EQUIPOS}
                color="inherit"
                className='nav-link servicio'
              >
                Instrumentación
              </Link>              
              <Nav.Link as={NavLink} to={ROUTES.TEAMWORK}>
                Personal
              </Nav.Link>
              <Nav.Link as={NavLink} to={ROUTES.INVESTIGACION}>
                Producto
              </Nav.Link>
              <Nav.Link as={NavLink} to={ROUTES.OFERTA}>
                Oferta de trabajo
              </Nav.Link>
              <NavDropdown  title="Política y Alcance del SGC" id="basic-nav-dropdown">
                <NavDropdown.Item href={`${process.env.PUBLIC_URL}/Política_Alcance_Files/ASGC.pdf`}
                target="_blank" rel="noopener noreferrer" className="text-wrap"> <Link>Alcance del Sistema de Gestión de Calidad.pdf</Link></NavDropdown.Item>
                <NavDropdown.Item href={`${process.env.PUBLIC_URL}/Política_Alcance_Files/PdeC.pdf`}
                target="_blank" rel="noopener noreferrer"><Link>Política de Calidad.pdf</Link></NavDropdown.Item>
              </NavDropdown>
              
              <button onClick={toggleModo} className='nav-link'>
                {modoNoche ? (
                  <Image src={noche} alt='noche' width="100%" height="100%"/>
                ) : (
                  <Image src={dia} alt='dia' width="100%" height="100%"/>
                )}
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="hero mb-5">
        <video autoPlay loop muted >
          <source src={video} type="video/mp4" />
        </video>
        <canvas ref={canvasRef} className="canvas" />
      </div>
    </>
  );
};