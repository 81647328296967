import React, { useEffect, useState } from "react";
import { Row, Container, Col, Card } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../TitleDerecha";
import { logrosData } from "../../constants/logrosData";

import "../../styles/components/Logro.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 50 },
};

export default function Logros({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [count, setCount] = useState(Array(logrosData.length).fill(0));

  useEffect(() => {
    if (inView) {
      control.start('visible');
      setCount(logrosData.map(() => 0));

      const interval = setInterval(() => {
        setCount((prevCount) => {
          const nextCount = prevCount.map((count, index) => {
            const finalValue = logrosData[index].dato;
            const increment = Math.ceil(finalValue / 25);
            return count + increment > finalValue ? finalValue : count + increment;
          });

          if (nextCount.every((value, index) => value === logrosData[index].dato)) {
            clearInterval(interval);
          }

          return nextCount;
        });
      }, 20);
    }
  }, [control, inView]);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (
    <motion.div className="box Logro" ref={ref} variants={boxVariant} initial="hidden" animate={control} style={styles}>
      <Title titulo="Actividades Ejecutadas" scroll={inView} />
      <Container className="pt-5 pb-5">
        <Row>
          {logrosData.map((data, index) => (
            <Col key={index} xl={3} lg={3} md={6} sm={6} xs={6} className="cuerpo">
              <Card border="0">
                <Card.Body>
                  <Card.Title className={`${modoNoche ? 'titulo-noche' : 'titulo-dia'}`} >
                    {data.titulo}
                  </Card.Title>
                  <Card.Text className={`${modoNoche ? 'texto-noche' : 'texto-dia'}`}>{count[index]}+</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </motion.div>
  );
}