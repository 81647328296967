import React from "react";
import { Col, Row, Nav } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

import { ANCLAS } from "../../constants/routes";
import laboratorio from "../../assets/images/logoLab.svg";

import "../../styles/components/Footer.scss";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <div>
      <Row className="footer contentColorBlue">
        <Col
          xs={6}
          sm={6}
          md={7}
          lg={7}
          xl={7}
          className="mt-1 mb-1 px-5 d-flex align-items-center logo"
        >
          <img src={laboratorio} alt="Logo" className="labLogo" width={100} />
        </Col>
        <Col
          xs={6}
          sm={6}
          md={5}
          lg={5}
          xl={5}
          className="d-grid align-items-center align-content-center mt-2"
        >
          <div className="d-flex align-items-center">
            <LocationOnIcon className="me-2" style={{ color: "white" }} />
            <Nav.Link
              href={ANCLAS.MAPA}
              className="text-white text-decoration-none"
              target="_blank"
            >
              Calzada del campesino 265, Colonia San Pablo. Colima, Col. C.P.
              28060
            </Nav.Link>
          </div>
          <div className="text-white d-flex align-items-center mt-2">
            <EmailIcon className="me-2" style={{ color: "white" }} />
            asistencia@msw.com.mx
          </div>
        </Col>
      </Row>
      <div
        className="text-center p-2 text-white"
        style={{ backgroundColor: "#3C3A3C" }}
      >
        MSW Tecnologías S.A. de C.V. {year}
      </div>
    </div>
  );
}
