import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { ROUTES } from "./routes";
import { IDesarrollo } from "../models/interfaces/IServicio";

import Aglobriq from "../assets/images/AglobriqColor.png";
import uno from "../assets/images/servicios/analisis.jpg";
import dos from "../assets/images/servicios/mineral.jpg";
import tres from "../assets/images/servicios/preparacion.jpg";
import cuatro from "../assets/images/servicios/proceso.jpg";
import cinco from "../assets/images/servicios/heroTarjetas.jpg";
import seis from "../assets/images/servicios/QaQc.jpg";

export const desarrolloData: IDesarrollo[] = [
  
  //Análisis Químicos y Físicos
  {
    idDesarrollo: 1,
    imgCarta: `${uno}`,
    titulo: "Análisis Químicos y Físicos",
    subtitulo: "Determinación cualitativa y cuantitativa de minerales.",
    style: "analisis",
    texto: (
      <>
        <Col md={6} lg={6} xl={6} xxl={6}/>
        <Col md={6} lg={6} xl={6} xxl={6} className="textoServicios">
          <Row>
            <Card.Text className="text-justify">
              Contamos con los equipos necesarios y el personal capacitado para lograr realizar análisis químicos y físicos de minerales. Los servicios que ofrecemos tanto en lo individual como en conjunto para la realización de caracterizaciones son:
            </Card.Text>
            <Card.Text className="text-justify">
              <ul>
                <li>Quebrado y pulverizado.</li>
                <li>Cribado, tamizado y homogenizado.</li>
                <li>Molienda (molino bond).</li>
                <li>Preconcentrado del mineral (polea magnética).</li>
                <li>Determinación de Fierro Total por Titulación de Dicromato de Potasio.</li>
                <li>Determinación de Fierro Magnético en Balanza Satmagan.</li>
                <li>Análisis de Azufre y Carbón por combustión.</li>
                <li>Análisis de Silicio, Calcio, Magnesio, Aluminio y Zinc por Espectrofotómetro de Absorción Atómica.</li>
                <li>Concentración de minerales por Tubo Davis.</li>
              </ul>
            </Card.Text>
          </Row>            
        </Col>
      </>
    ),
  },

  //Muestreos de Mineral
  {
    idDesarrollo: 2,
    imgCarta: `${dos}`,
    titulo: "Muestreos de Mineral",
    subtitulo: "Asegurar muestras representativas de minerales.",
    style: "mineral",
    texto: (
      <>
        <Col md={6} lg={6} xl={6} xxl={6} className="textoServicios">
          <Row>
            <Card.Text className="text-justify">
              Una de las operaciones más importantes, tanto en los trabajos de prospección como en los de exploración de yacimientos minerales, es el muestreo. Este es el único método para determinar la calidad de las menas. Por el muestreo se estudian las propiedades físicas y químicas del mineral. El proceso de muestreo es un conjunto de trabajos encaminados a determinar la composición (cuantitativa y cualitativa) de los componentes útiles e impurezas del mineral. 
            </Card.Text>
            <Card.Text className="text-justify">
              Este proceso comprende:
            </Card.Text>
            <Card.Text className="text-justify">
              <ul>
                <li>Selección y toma de muestras.</li>
                <li>Tratamiento de las muestras.</li>
                <li>Ensaye de las muestras.</li>
              </ul>
            </Card.Text>
          </Row>            
        </Col>              
      </>
    ),
  },
  
  //Preparacion de muestras especiales
  {
    idDesarrollo: 3,
    imgCarta: `${tres}`,
    titulo: "Preparación de muestras especiales",
    subtitulo: "Cristalografía - Microscopio - Corte Diamante - Hornos de Fusión.",
    style: "preparacion",
    texto: (
      <>  
        <Col md={6} lg={6} xl={6} xxl={6}/>
        <Col md={6} lg={6} xl={6} xxl={6} className="textoServicios">
          <Row>
            <Card.Text className="text-justify">
              Además de las áreas de investigación podemos realizar análisis independientes para pruebas especiales en la industria minero siderúrgica,, desde los procesos de extracción, hasta la reducción directa del pelet de mineral de fierro. 
            </Card.Text>
            <Card.Text className="text-justify">
              Algunas de las actividades que realizamos son: Preparación de ensayos de evaluación especiales para el desempeño de los hornos de fusión y/o Reducción Directa, preparación de muestras especiales con técnica de corte de pelet con disco de diamante, preparación de ensayos especiales donde se requiere equipo especial para el cribado de +1/4# y -1/4#, ensayos especiales por evaluación de performance en reducción y/o hornos fusión.
            </Card.Text>
          </Row>            
        </Col>            
      </>
    ),
  },

  //Investigación de procesos metalúrgicos
  {
    idDesarrollo: 4,
    imgCarta: `${cuatro}`,
    titulo: "Investigación de procesos metalúrgicos",
    subtitulo: "Optimizar metodologías industriales.",
    style: "investigacion",
    texto: (
      <>
        <Col md={6} lg={6} xl={6} xxl={6} className="textoServicios">
          <Row>
            <Card.Text className="text-justify">
              Contamos con un grupo de especialistas y alianzas estrategicas (empresas, cámaras y universidades) para llevar a cabo proyectos de investigación metalúrgicos, al día de hoy hemos realizado investigación en la cadena de las áreas primarias de la industria extractiva del fierro, desde la mina, pasando por el peletizado hasta reducción directa, investigando las condiciones de los procesos y el impacto en la calidad de los productos, así como la mejora de las condiciones físicas, químicas y de resistencia, para lograr obtener reducciones en costos y/o mejorar calidades y optimización de procesos.
            </Card.Text>
          </Row>            
        </Col>
      </>
    ),
  },

  //Investigación y distribución de productos y aditivos
  {
    idDesarrollo: 5,
    imgCarta: `${cinco}`,
    titulo: "Investigación y distribución de productos y aditivos",
    subtitulo: "Innovar y optimizar productos y/o aditivos en la industria minero - siderúrgica.",
    style: "distribucion",
    texto: (
      <>
        <Col md={6} lg={6} xl={6} xxl={6}/>
        <Col md={6} lg={6} xl={6} xxl={6} className="textoServicios">
          <Row>
            <Card.Text className="text-justify">
              Derivado de lo compleja y altamente competitiva que es la industria de la metalurgia del hierro, y con base en la mejora continua, creamos una ramificación dedicada a la innovación, investigación, distribución, desarrollo e implementación de aditivos nuevos.
            </Card.Text>
            <Card.Text className="text-justify">
              Iniciamos con pruebas a escala laboratorio, desarrollando productos con base a las necesidades que exige la industria minerosiderúrgica, para posteriormente realizar las pruebas necesarias directamente con nuestros clientes en las secciones de sus procesos industriales que intervengan con nuestros productos.
            </Card.Text>
            <Card.Text className="text-justify">
              Nuestro objetivo principal será siempre desarrollar y proveer productos que garanticen la optimización de los indicadores de producción, calidad, eficiencia y costo.
            </Card.Text>
            <NavLink to={ROUTES.INVESTIGACION}>
              <Image
                src={Aglobriq}
                width="50%"
                alt="Logo aglobriq"
              />
            </NavLink>
          </Row>            
        </Col>        
      </>
    ),
    aglobriq: 
    (
      <a href={ROUTES.INVESTIGACION}>
        <Image
          src={Aglobriq}
          alt="aglobriq"
          className="aglobriq"
          width="50%"
        ></Image>
      </a>
    )
  },

  //QA/QC
  {
    idDesarrollo: 6,
    imgCarta: `${seis}`,
    titulo: "QA/QC",
    subtitulo: "Aseguramiento y control de calidad.",
    style: "aseguramiento",
    texto: (
      <>
        <Col md={6} lg={6} xl={6} xxl={6} className="textoServicios">
          <Row>
            <Card.Text className="text-justify">
              Contamos con una empresa hermana, que nos brinda el apoyo necesario, guiándonos con las nuevas herramientas tecnológicas para mejorar contanstemente nuestros procesos. Logramos que la interacción cliente provedor se visualice dentro de plataformas web, creando el lazo de confianza entre lo que se realiza, los resultados obtenidos y la toma de decisiones y forma.
            </Card.Text>
          </Row>            
        </Col> 
      </>
    ),
  },
];
