import React, { useEffect } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../TitleIzquierda";
import { equipoDataDia } from "../../constants/equipoData";
import { equipoDataNoche } from "../../constants/equipoData";

import "../../styles/components/Equipo.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Equipos({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (    
    <>
      <div id="equipos" style={{marginBottom: "3rem", backgroundColor: `${modoNoche ? "black" : "white"}`}}></div>
      <section>
        <motion.div
          className="box equipo backgroundEquipo"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}
        >
          <Title titulo="Equipos e Instrumentación" scroll={inView} />
          <Container className="pt-5 pb-5">
            <Row>
              <p>
                Nuestro objetivo es ofrecer a nuestros clientes los mejores
                servicios de ingeniería y supervisión tecnificada, cumpliendo con
                los requerimientos de ejecución de obras de manera segura, eficiente
                y oportuna. Nos esforzamos por entregar servicios con el más alto
                índice de calidad.
              </p>
            </Row>
            {modoNoche ? (
                <Row className="fondos" >
                  {equipoDataNoche.map((data) => (
                    <Col className={`${data.hover} ${data.span} ${data.row}`} key={data.idEquipo}>
                      <Image
                        src={data.img}
                        alt={data.alt}
                        height="100%"
                        width="100%"
                      />
                      <figcaption>
                        <div className="titulo">{data.titulo}</div>
                        <div className="texto">{data.texto}</div>
                      </figcaption>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row className="fondos" >
                  {equipoDataDia.map((data) => (
                    <Col className={`${data.hover} ${data.span} ${data.row}`} key={data.idEquipo}>
                      <Image
                        src={data.img}
                        alt={data.alt}
                        height="100%"
                        width="100%"
                      />
                      <figcaption>
                        <div className="titulo">{data.titulo}</div>
                        <div className="texto">{data.texto}</div>
                      </figcaption>
                    </Col>
                  ))}
                </Row>
              )
            }            
          </Container>
        </motion.div>
      </section>
    </>
  );
}