import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Title from "../TitleDerecha";
import { desarrolloData } from "../../constants/servicioData";
import { ROUTES } from "../../constants/routes";

import "../../styles/components/Servicio.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Servicio({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (
    <>
      <div id="servicios" style={{marginBottom: "3rem", backgroundColor: `${modoNoche ? "black" : "white"}`}}></div>
      <section>
        <motion.div
          className="box Servicios"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}
        >
          <Title titulo="Servicios" scroll={inView}></Title>
          <Container className="pt-5">
            <Row className="justify-content-between fondos" xl={4} lg={4} md={3} sm={2} xs={1}>
              {desarrolloData.map((data) => (
                <Col className="fondo m-1">
                  <NavLink to={ROUTES.SERVICIOS}>
                    <Image
                      src={data.imgCarta}
                      width="100%"
                      alt={data.titulo}
                    />
                    <figcaption className="d-grid">
                      <div className="titulo">{data.titulo}</div>
                      <div className="texto">{data.subtitulo}</div>
                      <div className="servicio_boton">Conocer más</div>
                    </figcaption>
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Container>
        </motion.div>
      </section>   
    </>
  );
}