import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { TitleProps } from "../models/types/TitleProps.type";

import "../styles/components/TitleIzquierda.scss";

export default function TitleIzquierda({ titulo, scroll }:TitleProps) {
  const transforms = [
    {
      startMatrix: [0, 0, 0, 0, -37.5, -37.5],
      endMatrix: [1, 0, 0, 1, -37.5, -37.5],
    },
    {
      startMatrix: [0, 0, 0, 0, -65, -65],
      endMatrix: [1, 0, 0, 1, -65, -65],
    },
    {
      startMatrix: [0, 0, 0, 0, -95, -95],
      endMatrix: [1, 0, 0, 1, -95, -95],
    },
    {
      startMatrix: [1, 0, -0.78129, 1, 0, 0],
      endMatrix: [1, 0, -0.78129, 1, 20, 0],
    },
    {
      startMatrix: [1, 0, -0.78129, 1, 0, 0],
      endMatrix: [1, 0, -0.78129, 1, 40, 0],
    },
    {
      startMatrix: [1, 0, -0.78129, 1, 0, 0],
      endMatrix: [1, 0, -0.78129, 1, 60, 0],
    },
  ];

  const [transformValues, setTransformValues] = useState(transforms.map(() => "matrix(0, 0, 0, 0, 0, 0)"));
  
  useEffect(() => {
    if (scroll) {
      transforms.forEach((transform, index) => {
        const { startMatrix, endMatrix } = transform;
        const duration = 1500; // Duración de la transición en milisegundos
        const interval = 10; // Intervalo para actualizar la transformación en milisegundos
        const steps = Math.ceil(duration / interval);
        const stepValues = startMatrix.map((value, i) => (endMatrix[i] - value) / steps);
  
        let currentStep = 0;
        const timeout = setInterval(() => {
          currentStep++;
  
          const matrixValues = startMatrix.map((value, i) => value + stepValues[i] * currentStep);
          const newTransformValue = `matrix(${matrixValues.join(",")})`;
  
          setTransformValues(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = newTransformValue;
            return newValues;
          });
  
          if (currentStep === steps) {
            clearInterval(timeout);
          }
        }, interval);
  
        return () => {
          clearInterval(timeout);
        };
      });
    }
  }, [scroll]);

  return(
    <div className="TitleIzquierda" >
      <div className="capa-inferior" data-anim="group"/>
      <section className="anonce-block">
        <ul className="anonce-block__parts">
          <li className="anonce-block__part" style={{ transform: transformValues[3]}}></li>
          <li className="anonce-block__part" style={{ transform: transformValues[4]}}></li>
          <li className="anonce-block__part" style={{ transform: transformValues[5]}}></li>
        </ul>
        <ul className="anonce-block__cicles">
          {transformValues.map((transformValue, index) => (
            <li key={index} className="anonce-block__cicle" style={{ transform: transformValue, background: 'transparent' }}></li>
          ))}
        </ul>
        <Container>
          <div className="anonce-block__container container capa-superior">
            <div className="anonce-block__body">
              <span className="anonce-block__title anonce-block__title_smaller" data-anim-stagger="fade-top" data-mob-anim="group" data-mob-anim-stagger="fade-top">
                {titulo}
              </span>
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}