export const logrosData = [
  {
    titulo: "Titulación de Fierro Total",
    dato: 3200,
    id: 1,
  },
  {
    titulo: "Fierro Magnético por Satmagan",
    dato: 4500,
    id: 2,
  },
  {
    titulo: "Moliendas a -400 Mallas",
    dato: 2600,
    id: 3,
  },
  {
    titulo: "Caracterizaciones de Proceso",
    dato: 2900,
    id: 4,
  },
];
