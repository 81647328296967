import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Title from "../TitleIzquierda";

import evidenciaTres from "../../assets/images/evidenciaTres.jpg";
import evidenciaCuatro from "../../assets/images/evidenciaCuatro.jpg";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function ProductoAglobriq() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  
  return (
    <motion.div
    className="box"
    ref={ref}
    variants={boxVariant}
    initial="hidden"
    animate={control}>
      <Title titulo="Producto AgloBriq" scroll={inView}/>
      <Container className="pt-5 pb-5">
        <Row>
          <Col className="me-2">
            <img
              src={evidenciaTres}
              width="50%"
              height="100%"
              alt="evidencia tres"
            />
          </Col>
          <Col className="me-2">
            <img
              src={evidenciaCuatro}
              width="50%"
              height="100%"
              alt="evidencia cuatro"
            />
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
