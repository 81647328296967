import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import logoWhats from "../../assets/images/logoWhats.svg";

export default function WhatsApp() {
  return (
    <FloatingWhatsApp
      accountName="Laboratorio MSW"
      phoneNumber="+5213121145616"
      darkMode={true}
      chatMessage="Hola, ¿En que podemos ayudarte?"
      statusMessage="Horario de respuesta de 9am - 5pm"
      avatar={logoWhats}
      allowClickAway={true}
      messageDelay={0}
      placeholder="Mensaje"
    />
  );
}
