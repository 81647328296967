import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Row, Container, Image, Col } from "react-bootstrap";
import { useAnimation, motion } from "framer-motion";

import Title from "../../components/TitleIzquierda";
import TeamModal from "../../components/modals/TeamModal";
import { teamworkData } from "../../constants/teamworkData";
import { IEmpleado } from "../../models/interfaces/IEmpleado";

import "../../styles/components/TeamWork.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Teamwork({ modoNoche }) {
  const [estadoModal, setEstadoModal] = useState(false);
  const [currentInfo, setCurrentInfo] = useState<IEmpleado>({
    nombre: "",
    titulo: "",
    alt: "",
    srcAlt: ``,
    src: ``,
    id: 0,
  });

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  return (
    <motion.div
      className="box Teamwork mt-5"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      style={styles}
    >
      <Title titulo="Personal" scroll={inView}/>
      <Container>
        <Row className="mt-5" xl="5" lg="4" md="3" sm="2" xs="1">
          {teamworkData.map((data: IEmpleado) => (
            <Col className="img" key={data.id}>
              <Image
                src={data.src}
                alt={data.alt}
                style={{filter: data.filter}}
                width={200}
                className="imgOriginal"
                onClick={() => {
                  setCurrentInfo(data);
                  setEstadoModal(true);
                }}
              />
              <Image
                src={data.src}
                alt={data.alt}
                width={200}
                height={200}
                className="imgAlt"
                onClick={() => {
                  setCurrentInfo(data);
                  setEstadoModal(true);
                }}
              />
              <h3 className="nombre">{data.nombreCompleto}</h3>
              <h4 className="texto mb-4">{data.titulo}</h4>
            </Col>
          ))}
        </Row>
        <TeamModal
          estadoModal={estadoModal}
          cambiarEstadoModal={setEstadoModal}
          infoEmpleado={currentInfo}
        />
      </Container>
    </motion.div>
  );
}
