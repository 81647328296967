import React, { useEffect } from "react";
import { Container, ListGroup, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Title from "../TitleIzquierda";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function VentajasAglobriq() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}>
      <Title titulo="Ventajas del aditivo" scroll={inView}/>
      <Container className="pt-5 pb-5">
        <Row>
          <ListGroup as="ul" className="d-flex">
            <ListGroup.Item as="li" className="border-0 bg-transparent">
             <div> 1.- No requiere sistemas especiales para el almacenamiento.</div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="border-0 bg-transparent">
              <div>2.- No requiere sistemas especiales de transporte.</div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="border-0 bg-transparent">
              <div>3.- AgloBriq presenta menor densidad que el silicato de sodio, que se traduce en mayor facilidad para su manejo en los tanques, tubería y sistemas de manejo.</div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="border-0 bg-transparent">
              <div>4.- El producto no representa ningún riesgo para las instalaciones o el personal.</div>
            </ListGroup.Item>
          </ListGroup>
        </Row>
      </Container>
    </motion.div>
  );
}