import React from "react";
import { Col } from "react-bootstrap";

import { IEmpleado } from "../models/interfaces/IEmpleado";

import StarIcon from "@mui/icons-material/Star";
import SchoolIcon from "@mui/icons-material/School";

import polo from "../assets/images/trabajadores/poloJSerio.jpg";
import isis from "../assets/images/trabajadores/isisSeria.jpg";
import elsy from "../assets/images/trabajadores/elsySerio.jpg";
import andrea from "../assets/images/trabajadores/andreaSerio.jpg";
import gerardo from "../assets/images/trabajadores/gerardoSerio.jpg";
import poloB from "../assets/images/trabajadores/poloBSerio.jpg";
import miguel from "../assets/images/trabajadores/miguelSerio.jpg";
import frank from "../assets/images/trabajadores/frankSerio.jpg";
import fidel from "../assets/images/trabajadores/fidelSerio.jpg";
import francisco from "../assets/images/trabajadores/franciscoSerio.jpg";
import hugo from "../assets/images/trabajadores/hugoSerio.jpg";
import angel from "../assets/images/trabajadores/angelSerio.jpg";
import tacho from "../assets/images/trabajadores/tachoSerio.jpg";
import jose from "../assets/images/trabajadores/joseSerio.jpg";
import david from "../assets/images/trabajadores/davidSerio.jpg";
import joselin from "../assets/images/trabajadores/joselinSerio.jpg";
import pablo from "../assets/images/trabajadores/pabloSerio.jpg";
import crecencio from "../assets/images/trabajadores/crecencioSerio.jpg";

import poloAlt from "../assets/images/trabajadores/poloJChamba.jpg";
import isisAlt from "../assets/images/trabajadores/isisChamba.jpg";
import elsyAlt from "../assets/images/trabajadores/elsyChamba.jpg";
import andreaAlt from "../assets/images/trabajadores/andreaChamba.jpg";
import gerardoAlt from "../assets/images/trabajadores/gerardoChamba.jpg";
import poloBAlt from "../assets/images/trabajadores/poloBSChamba.jpg";
import miguelAlt from "../assets/images/trabajadores/miguelChamba.jpg";
import frankAlt from "../assets/images/trabajadores/frankChamba.jpg";
import fidelAlt from "../assets/images/trabajadores/fidelChamba.jpg";
import franciscoAlt from "../assets/images/trabajadores/franciscoChamba.jpg";
import hugoAlt from "../assets/images/trabajadores/hugoChamba.jpg";
import angelAlt from "../assets/images/trabajadores/angelChamba.jpg";
import tachoAlt from "../assets/images/trabajadores/tachoChamba.jpg";
import joseAlt from "../assets/images/trabajadores/joseChamba.jpg";
import davidAlt from "../assets/images/trabajadores/davidChamba.jpg";
import joselinAlt from "../assets/images/trabajadores/joselinChamba.jpg";
import pabloAlt from "../assets/images/trabajadores/pabloChamba.jpg";
import crecencioAlt from "../assets/images/trabajadores/crecencioChamba.jpg"

export const teamworkData: IEmpleado[] = [
  //Polo Maldonado
  {
    nombreCompleto: "Polo Maldonado",
    nombre: "Marco Polo",
    apellido: "Maldonado Rayas",
    descripcion:
      "Apasionado en la re-ingeniería de procesos, con alta experiencia en organización de empresas y negociación efectiva, mi principal función radica en la supervisión de proyectos de innovación tecnológica y en la detección oportuna de las necesidades cambiantes del mercado a través de la mezcla de tecnología, conocimientos e innovación.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"La estrategia en los proyectos la aporta usted, la ingeniería de proyectos, MSW."</p>
      </Col>
    ),
    titulo: "Director general",
    alt: "Polo Maldonado",
    src: `${polo}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Químico Metalúrgico</p>
          </div>
        </Col>
      </>
    ),
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>
              Administración de proyectos | Mejora Continua | Negociación |
              Planificación Estratégica | Minería | Ingeniería de Procesos
            </p>
          </div>
        </Col>
      </>
    ),
    id: 1,
    srcAlt: `${poloAlt}`,
    filter: "sepia(80%)",
  },

  //Isis Segundo
  {
    nombreCompleto: "Isis Segundo",
    nombre: "Isis Judith",
    apellido: "Segundo Lauriano",
    descripcion: "Me considero una persona responsable, pro activa y honesta.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Solo yo puedo cambiar mi vida. Nadie puede hacerlo por mí."</p>
      </Col>
    ),
    titulo: "Administrador contable",
    alt: "Isis Segundo",
    src: `${isis}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Licenciada en Administración</p>
          </div>
        </Col>
      </>
    ),
    id: 2,
    srcAlt: `${isisAlt}`,
    filter: "sepia(80%)",
  },

  //Elsy Bravo
  {
    nombreCompleto: "Elsy Bravo",
    nombre: "Elsy Yacaranday",
    apellido: "Bravo Michel",
    descripcion: "Me considero una persona dedicada, responsable y proactiva.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Sé el cambio que quieres ver en el mundo."</p>
      </Col>
    ),
    titulo: "Administración y recursos humanos",
    alt: "Elsy Bravo",
    src: `${elsy}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Licenciada en Administración</p>
          </div>
        </Col>
      </>
    ),
    id: 3,
    srcAlt: `${elsyAlt}`,
    filter: "sepia(80%)",
  },

  //Andrea Dominguez
  {
    nombreCompleto: "Andrea Dominguez",
    nombre: "Andrea Giselle",
    apellido: "Dominguez González",
    descripcion:
      "Me considero una persona responsable y trabajadora con ganas de seguir aprendiendo.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"No hay secretos para el éxito, este se alcanza preparándose, trabajando arduamente y aprendiendo del fracaso."</p>
      </Col>
    ),
    titulo: "Supervisora de laboratorio físico químico",
    alt: "Andrea Dominguez",
    src: `${andrea}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniera Químico Metalúrgico</p>
          </div>
        </Col>
      </>
    ),
    id: 4,
    srcAlt: `${andreaAlt}`,
    filter: "sepia(80%)",
  },

  //Gerardo Cabellos
  {
    nombreCompleto: "Gerardo Cabellos",
    nombre: "Gerardo",
    apellido: "Cabellos Magaña",
    descripcion:
      "Me considero una persona capaz y consiente de lo que hago día a día enfocado a obtener los mejores resultados y adquirir nuevas habilidades y conocimientos.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Si algo puede salir mal, saldrá mal."</p>
      </Col>
    ),
    titulo: "Ing. de combustión",
    alt: "Gerardo Cabellos",
    src: `${gerardo}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Químico Metalúrgico</p>
          </div>
        </Col>
      </>
    ),
    id: 5,
    srcAlt: `${gerardoAlt}`,
    filter: "sepia(80%)",
  },

  //Polo Rodriguez
  {
    nombreCompleto: "Polo Rodríguez",
    nombre: "Polo Saul",
    apellido: "Rodríguez Sarmienta",
    descripcion:
      "Aptitudes para cualquier actividad que se requiera, proactivo, responsable.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"La imaginación es más importante que el conocimiento. El conocimiento es limitado, mientras que la imaginación no."</p>
      </Col>
    ),
    titulo: "Ing. Físico de concentración magnética",
    alt: "Polo Rodriguez",
    src: `${poloB}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Químico Metalúrgico</p>
          </div>
        </Col>
      </>
    ),
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>
              Disponibilidad para aprender | Resolver problemas | Apasionado |
              Analizador de datos | Optimización de procesos
            </p>
          </div>
        </Col>
      </>
    ),
    id: 6,
    srcAlt: `${poloBAlt}`,
    filter: "sepia(80%)",
  },

  //Pablo Castillo
  {
    nombreCompleto: "Juan Castillo",
    nombre: "Juan Pablo",
    apellido: "Castillo Flores",
    titulo: "Ing. de procesos",
    alt: "Juan Pablo",
    src: `${pablo}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Químico Metalúrgico</p>
          </div>
        </Col>
      </>
    ),
    id: 7,
    srcAlt: `${pabloAlt}`,
    filter: "sepia(80%)",
  },

  //Miguel Gutierrez
  {
    nombreCompleto: "Miguel Gutierrez",
    nombre: "Miguel",
    apellido: "Gutierrez Valencia",
    descripcion:
      "Me considero una persona amable, inteligente, planificadora y con muchísimas ganas de crecer laboralmente. Colabore con ingenieros de procesos de Ternium en actividades como medición de altura de bola en molinos, medición del desgaste de liners y tromels, además de actividades de laboratorio como molienda y triturado de mineral, pruebas de flotación de azufre y concentración de mineral en tambores magnéticos, por mencionar algunos.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Esperando lo mejor, preparado para lo peor."</p>
      </Col>
    ),
    titulo: "Ing. de procesos",
    alt: "Miguel Gutierrez",
    src: `${miguel}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Bioquímico</p>
          </div>
        </Col>
      </>
    ),    
    id: 8,
    srcAlt: `${miguelAlt}`,
    filter: "sepia(80%)",
  },

  //Frank Flores
  {
    nombreCompleto: "Frank Flores",
    nombre: "Frank Selibi",
    apellido: "Flores Villanueva",
    descripcion: "Soy una persona comprometida, confiable y enérgica, siempre dispuesta a asumir nuevos desafíos y hacer lo necesario para lograr resultados positivos.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"El conocimiento es la llave de la oportunidad."</p>
      </Col>
    ),
    titulo: "Analista de vía húmeda",
    alt: "Frank FLores",
    src: `${frank}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Bioquímico</p>
          </div>
        </Col>
      </>
    ),
    id: 9,
    srcAlt: `${frankAlt}`,
    filter: "sepia(80%)",
  },

  //Fidel Sánchez
  {
    nombreCompleto: "Fidel Sánchez",
    nombre: "Fidel",
    apellido: "Sánchez Ángulo",
    descripcion: "Me caracterizo por ser una persona diligente, puntual y dispuesta a asumir responsabilidades. Mi enfoque proactivo me impulsa a buscar constantemente formas de mejorar y contribuir al cambio que deseo ver en mi entorno.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Elige un empleo que te guste y nunca tendrás que volver a trabajar en tu vida."</p>
      </Col>
    ),
    titulo: "Analista físico-químico",
    alt: "Fidel Sánchez",
    src: `${fidel}`,
    id: 10,
    srcAlt: `${fidelAlt}`,
    filter: "sepia(80%)",
  },

  //Francisco Macías
  {
    nombreCompleto: "Francisco  Macías",
    nombre: "Francisco",
    apellido: "Macías Flores",
    descripcion: "Considero que la dedicación, la responsabilidad y la iniciativa son valores fundamentales para lograr un impacto positivo. Me esfuerzo por ser una persona que toma la iniciativa para generar el cambio que quiero ver en el mundo y fomentar un entorno más justo y equitativo.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Renunciar a la idea de ganar sin antes empezar, equivale a perder sin antes luchar."</p>
      </Col>
    ),
    titulo: "Analista fisico",
    alt: "Francisco  Macías",
    src: `${francisco}`,
    id: 11,
    srcAlt: `${franciscoAlt}`,
    filter: "sepia(80%)",
  },

  //Hugo Lucatero
  {
    nombreCompleto: "Hugo Lucatero",
    nombre: "Hugo Enrique",
    apellido: "Lucatero Orozco",
    descripcion: "Me considero una persona comrpometida, confiable y emprendedora.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"La acción es la clave para moldear el futuro."</p>
      </Col>
    ),
    titulo: "Analista fisico",
    alt: "Hugo Lucatero",
    src: `${hugo}`,
    id: 12,
    srcAlt: `${hugoAlt}`,
    filter: "sepia(80%)",
  },

  //Luis Arreola
  {
    nombreCompleto: "Luis Arreola",
    nombre: "Luis Angel",
    apellido: "Arreola López",
    descripcion: "Destaco por mi profesionalismo, adaptibilidad y capacidad de trabajo en equipo.",
    lema:(
      <Col className="lema text-white text-center">
        <p className="mt-3 mb-3">"Sé la inspiración que deseas ver en el mundo."</p>
      </Col>
    ),
    titulo: "Analista fisico",
    alt: "Luis Arreola",
    src: `${angel}`,
    id: 13,
    srcAlt: `${angelAlt}`,
    filter: "sepia(80%)",
  },

  //Anastacio Jasso
  {
    nombreCompleto: "Anastacio Jasso",
    nombre: "Anastacio",
    apellido: "Jasso Anguiano",
    titulo: "Ing. Químico Metalúrgico",
    alt: "Anastacio Jasso",
    src: `${tacho}`,
    id: 14,
    srcAlt: `${tachoAlt}`,
    filter: "sepia(80%)",
  },

  //José Salazar
  {
    nombreCompleto: "José Salazar",
    nombre: "José Bernabé",
    apellido: "Salazar Alcaraz",
    titulo: "Analista fisico",
    alt: "José Salazar",
    src: `${jose}`,
    id: 15,
    srcAlt: `${joseAlt}`,
    filter: "sepia(80%)",
  },

  //David Aquino
  {
    nombreCompleto: "David Aquino",
    nombre: "David",
    apellido: "Aquino Benavides",
    titulo: "Analista fisico",
    alt: "David Aquino",
    src: `${david}`,
    id: 16,
    srcAlt: `${davidAlt}`,
    filter: "sepia(80%)",
  },

  //Joselin Aguilar
  {
    nombreCompleto: "Joselín Aguilar",
    nombre: "Joselín",
    apellido: "Aguilar Gallardo",
    titulo: "Auxiliar laboratorista",
    alt: "Joselín Aguilar",
    src: `${joselin}`,
    id: 17,
    srcAlt: `${joselinAlt}`,
    filter: "sepia(80%)",
  },

  //Crecencio Hernandez
  {
    nombreCompleto: "Crecencio Hernández",
    nombre: "Crecencio",
    apellido: "Hernández Hernández",
    titulo: "Auxiliar laboratorista",
    alt: "Crecencio Hernández",
    src: `${crecencio}`,
    estudio: (
      <>
        <Col lg={1}>
          <SchoolIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Ingeniero Químico</p>
          </div>
        </Col>
      </>
    ),
    id: 18,
    srcAlt: `${crecencioAlt}`,
    filter: "sepia(80%)",
  },
];
