import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { IEmpleado } from "../../models/interfaces/IEmpleado";

import "../../styles/components/TeamWork.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

export default function TeamModal(props: TeamModalProps) {
  const { estadoModal, cambiarEstadoModal, infoEmpleado } = props;

  return (
    <Modal
      show={estadoModal}
      onHide={() => cambiarEstadoModal(false)}
      dialogClassName="modal-100w modalTeam"
      className="modal-100w"
      size="xl"
    >
      <ThemeProvider theme={theme}>
        <Modal.Header closeButton closeVariant="white" />
        <Modal.Body className="show-grid p-0 .bg-secondary">
          <Container className="p-0">
            <Row>
              <Col lg={6} className="p-4 modalColor">
                <Row>
                  <Col>
                    <h1 className="text-white nombreModal">{infoEmpleado.nombre}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 className="text-white">{infoEmpleado.apellido}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className="titulo">{infoEmpleado.titulo}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-white text-justify">
                      {infoEmpleado.descripcion}
                    </p>
                  </Col>
                </Row>
                <Row>
                  {infoEmpleado.lema}
                </Row>
                <Row className="mt-3">
                  {infoEmpleado.estudio}
                </Row>
                <Row>
                  {infoEmpleado.reconocimiento}
                </Row>
              </Col>
              <Col lg={6}>
                <img
                  src={infoEmpleado.srcAlt}
                  alt="imagen"
                  className="w-100 h-100"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </ThemeProvider>
    </Modal>
  );
}

export type TeamModalProps = {
  estadoModal: boolean;
  cambiarEstadoModal: Function;
  infoEmpleado: IEmpleado;
};
