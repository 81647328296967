import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

import Title from "../TitleIzquierda";
import TitleText from "../Title"
import { valoresData } from "../../constants/valoresData";
import logoHistoria from "../../assets/images/logoHistoria.svg";

import "../../styles/components/InfoEmpresa.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function InfoEmpresa({ modoNoche }) {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [maxHeight, setMaxHeight] = useState("auto");
  const paginationRef = useRef(null);

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);  

  useEffect(() => {
    // Función para calcular la altura máxima
    const calculateMaxHeight = () => {
      // Encuentra la altura máxima entre los valoresData
      const maxCardHeight = valoresData.reduce((maxHeight, data) => {
        let cardTextHeight = data.valor.length * 1.40;

        // Verificar si la pantalla es mayor a 767px
        if (window.innerWidth > 767) {
          cardTextHeight = data.valor.length * 1.1;
        }

        return cardTextHeight > maxHeight ? cardTextHeight : maxHeight;
      }, 0);

      setMaxHeight(`${maxCardHeight}px`); // Establece la altura máxima en píxeles
    };

    // Llama a calculateMaxHeight() cuando se carga la página
    calculateMaxHeight();

    // Agrega un event listener para el evento 'resize'
    window.addEventListener("resize", calculateMaxHeight);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", calculateMaxHeight);
    };
  }, []);

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };


  return (
    <motion.div 
      className="box InfoEmpresa"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      style={styles}>
      <Title titulo="¿Quienes somos?" scroll={inView}/>
      <Container className="pt-5">        
        <div className="text-justify">
          <p>
            Laboratorio MSW nace con el propósito de explorar un sector de gran importancia para la zona geográfica, brindando servicios a las empresas mineras de la región. Buscamos establecer relaciones con las mejores prácticas operativas a través de procesos, procedimientos y formatos preestablecidos, fusionándolos con herramientas tecnológicas desarrolladas internamente.
          </p>
          <Row className="mt-5">
            <TitleText titulo="Misión" />
          </Row>
          <Row>
            Aportar a las empresas mineras la certeza de que sus procesos son eficientes y controlados, a través de la precisión de nuestros análisis.
          </Row>

          <Row className="mt-5 d-grid justify-content-end">
            <TitleText titulo="Visión" />
          </Row>
          <Row className="d-grid justify-content-end">
            Caracterizar los insumos mineros para optimizar los procesos industriales y tener un menor impacto en nuestro medio ambiente.
          </Row>

          <Row className="mt-5">
            <TitleText titulo="Valores" />
            <Swiper
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{ el: paginationRef.current, clickable: true }}
              spaceBetween={30}
              navigation={true}
              loop={true}
              modules={[Navigation, Autoplay, Pagination]}
              className="mySwiper mt-3 mb-3"
            >
              {valoresData.map((data) => (
                <div key={data.idValor}>
                  <SwiperSlide>
                    <Card className="m-1">
                      <Card.Img variant="top" src={data.src} alt={data.titulo} height="100%" width="100%"/>
                      <Card.Body style={{ height: maxHeight }}>
                        <Card.Title className="titulo">{data.titulo}</Card.Title>
                        <Card.Text className="texto" dangerouslySetInnerHTML={{__html:data.valor}}/>
                      </Card.Body>
                    </Card>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
            <div ref={paginationRef} className={`${modoNoche ? "carousel-dots-noche" : "carousel-dots-dia"}`} style={{}}></div>
          </Row> 

          <Row className="mt-5 d-grid justify-content-end">
            <TitleText titulo="Historia" />
          </Row>
          <Row>
            <Col className="p-3 d-flex" xl={5} lg={5} style={{backgroundColor: `${modoNoche ? "white" : ""}`}}>
              <Image src={logoHistoria} width={"100%"} height="100%" alt="logo historia"/>
            </Col>
            <Col xl={7} lg={7} className="historia p-3">
              <Row>
                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                  <div className="linea-vertical"></div>
                </Col>
                <Col>
                  <Row>
                    Laboratorio  MSW  se  crea  hace  más  de  8  años,  derivado  de  las  necesidades  del  sector  de  la  industria extractiva y el ímpetu de nuestros accionistas por poder brindar un servicio de mayor calidad en menor tiempo. Aunado a lo anterior, existía una estrecha relación con las empresas mineras de la región, lo que nos ha permitido forjar el camino para la investigación de procesos mineros y metalúrgicos, así mismo, la relación con la  Universidad de  Colima  nos  ha  permitido  contar  con un  equipo  de  ingenieros  altamente capacitados  en  los  análisis  tanto  físicos  como  químicos  que  se  realizan,  así  como  con  una  profunda comprensión teórica de las técnicas de análisis que se manejan.
                  </Row>
                  <Row className="mt-3">
                    Contamos con un departamento de tecnología dentro de la empresa que mantiene el constante desarrollo y actualización de los tableros de control y formatos de reporte de resultados, buscando así, tecnificar la estructura. 
                  </Row>
                  <Row className="mt-3">
                    La integración que hemos conseguido de todo lo anterior nos permite ser el laboratorio en el que deseas caracterizar tu futuro.
                  </Row> 
                </Col>
              </Row>                   
            </Col>
          </Row>             
        </div>
      </Container>
    </motion.div>
  );
}