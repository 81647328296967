import React from "react";
import Principal from "../../components/Home/InfoEmpresa";
import Equipos from "../../components/Home/Equipos";
import Servicio from "../../components/Home/Servicio";
import Logros from "../../components/Home/Logros";

export default function Home({ modoNoche }) {
  return (
    <div className="content">
      <Principal modoNoche={modoNoche}/>
      <Servicio modoNoche={modoNoche}/>
      <Equipos modoNoche={modoNoche}/>
      <Logros modoNoche={modoNoche}/>
    </div>
  );
}
