import React from "react";
import { IEquipo } from "../models/interfaces/IEquipo";

import uno from "../assets/images/equipos/equipo1.jpg";
import dos from "../assets/images/equipos/equipo2.jpg";
import tres from "../assets/images/equipos/equipo3.jpg";
import cuatro from "../assets/images/equipos/equipo4.jpg";
import cinco from "../assets/images/equipos/equipo5.jpg";
import seis from "../assets/images/equipos/equipo6.jpg";
import siete from "../assets/images/equipos/equipo7.jpg";
import texto from "../assets/images/equipos/Texto.jpg";
import textoOscuro from "../assets/images/equipos/TextoOscuro.jpg";

export const equipoDataDia: IEquipo[] = [  

  //Elementrac
  {
    img: `${uno}`,
    titulo:(
      <p>
        <strong>Elementrac CS-i</strong>
      </p>
    ),
    texto: (
      <p>
        Análisis por Combustión Inductiva de Azufre y Carbón.
      </p>
    ),
    alt: "equipo1",
    idEquipo: 1,
    hover:"fondo",
  },  

  //Titulacion de FeT
  {
    img: `${dos}`,
    titulo:(
      <p>
        <strong>Titulación de FeT por Vía Húmeda</strong>
      </p>
    ),
    texto: (
      <p>
        Determinación de Fierro Total por titulación de Dicromato de Potasio.
      </p>
    ),
    alt: "equipo2",
    idEquipo: 2,
    row: 'row',
    hover:"fondo",
  },     

  //Balanza satmagan
  {
    img: `${tres}`,
    titulo:(
      <p>
        <strong>Balanza Satmagan 135</strong>
      </p>
    ),
    texto: (
      <p>
        Analizador de magnetita en concentraciones de mineral de hierro.
      </p>
    ),
    alt: "equipo3",
    idEquipo: 3,
    hover:"fondo",
  },  

  //Tubo davis
  {
    img: `${cuatro}`,
    texto: (
      <>
        <p>
          <strong>Tubo Davis</strong>
        </p>
        <p>
          Equipo electromagnético para concentración en húmedo de mineral de hierro.
        </p>
      </>
    ),
    alt: "equipo4",
    idEquipo: 4,
    hover:"fondo",
  },

  //Espectrofotómetro de Absorción Atómica iCE 3300
  {
    img: `${cinco}`,
    titulo:(
      <p>
        <strong>Espectrofotómetro de Absorción Atómica iCE 3300</strong>
      </p>
    ),
    texto: (
      <p>
        Equipo Instrumental que permite medir la concentración de Al, Si, Ca, Mg y Zn.
      </p>
    ),
    alt: "equipo5",
    idEquipo: 5,
    row: 'row',
    hover:"fondo",
  },            

  //Molino bond
  {
    img: `${seis}`,
    titulo:(
      <p>
        <strong>Molino Bond de 12" y 8"</strong>
      </p>
    ),
    texto: (
      <p>
        Equipo físico para molienda de mineral a partículas menores de 400 mallas.
      </p>
    ),
    alt: "equipo6",
    idEquipo: 6,
    row: 'row',
    hover:"fondo",
  },

  //Preconcentración Magnética
  {
    img: `${siete}`,
    titulo:(
      <p>
        <strong>Preconcentración Magnética</strong>
      </p>
    ),
    texto: (
      <p>
        Sistema transportador con banda de 20" y polea magnética AIP de 12" para preconcentrar mineral de fierro.
      </p>
    ),
    alt: "equipo7",
    idEquipo: 7,
    hover:"fondo",
  }, 

  //Texto
  {
    img: `${texto}`,
    alt: "texto",
    idEquipo: 8,
    span: 'span',
  },    
];

export const equipoDataNoche: IEquipo[] = [  

  //Elementrac
  {
    img: `${uno}`,
    titulo:(
      <p>
        <strong>Elementrac CS-i</strong>
      </p>
    ),
    texto: (
      <p>
        Análisis por Combustión Inductiva de Azufre y Carbón.
      </p>
    ),
    alt: "equipo1",
    idEquipo: 1,
    hover:"fondo",
  },  

  //Titulacion de FeT
  {
    img: `${dos}`,
    titulo:(
      <p>
        <strong>Titulación de FeT por Vía Húmeda</strong>
      </p>
    ),
    texto: (
      <p>
        Determinación de Fierro Total por titulación de Dicromato de Potasio.
      </p>
    ),
    alt: "equipo2",
    idEquipo: 2,
    row: 'row',
    hover:"fondo",
  },     

  //Balanza satmagan
  {
    img: `${tres}`,
    titulo:(
      <p>
        <strong>Balanza Satmagan 135</strong>
      </p>
    ),
    texto: (
      <p>
        Analizador de magnetita en concentraciones de mineral de hierro.
      </p>
    ),
    alt: "equipo3",
    idEquipo: 3,
    hover:"fondo",
  },  

  //Tubo davis
  {
    img: `${cuatro}`,
    texto: (
      <>
        <p>
          <strong>Tubo Davis</strong>
        </p>
        <p>
          Equipo electromagnético para concentración en húmedo de mineral de hierro.
        </p>
      </>
    ),
    alt: "equipo4",
    idEquipo: 4,
    hover:"fondo",
  },

  //Espectrofotómetro de Absorción Atómica iCE 3300
  {
    img: `${cinco}`,
    titulo:(
      <p>
        <strong>Espectrofotómetro de Absorción Atómica iCE 3300</strong>
      </p>
    ),
    texto: (
      <p>
        Equipo Instrumental que permite medir la concentración de Al, Si, Ca, Mg y Zn.
      </p>
    ),
    alt: "equipo5",
    idEquipo: 5,
    row: 'row',
    hover:"fondo",
  },            

  //Molino bond
  {
    img: `${seis}`,
    titulo:(
      <p>
        <strong>Molino Bond de 12" y 8"</strong>
      </p>
    ),
    texto: (
      <p>
        Equipo físico para molienda de mineral a partículas menores de 400 mallas.
      </p>
    ),
    alt: "equipo6",
    idEquipo: 6,
    row: 'row',
    hover:"fondo",
  },

  //Preconcentración Magnética
  {
    img: `${siete}`,
    titulo:(
      <p>
        <strong>Preconcentración Magnética</strong>
      </p>
    ),
    texto: (
      <p>
        Sistema transportador con banda de 20" y polea magnética AIP de 12" para preconcentrar mineral de fierro.
      </p>
    ),
    alt: "equipo7",
    idEquipo: 7,
    hover:"fondo",
  }, 

  //Texto
  {
    img: `${textoOscuro}`,
    alt: "texto",
    idEquipo: 8,
    span: 'span',
  },    
];
