import React,{ useEffect, useRef} from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { Container, Row } from "react-bootstrap";

import TitleIzquierda from "../../components/TitleIzquierda";
import TitleDerecha from "../../components/TitleDerecha";
import { desarrolloData } from "../../constants/servicioData";

import video from "../../assets/videos/serviciosVideo.mp4";
import logo from "../../assets/images/servicios/Servicios.png";

import "../../styles/components/infoServicios.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Servicios({modoNoche}) {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    let position = 0;
  
    const drawImage = (image, position) => {
      if (ctx && canvas) {
        // Configurar el tamaño del canvas
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        // Calcular las coordenadas y tamaño de la imagen
        const scale = Math.min(canvas.width / image.width, canvas.height / image.height) / 3;
        const imageWidth = image.width * scale;
        const imageHeight = image.height * scale;
        const x = position * (canvas.width - imageWidth) /1.05;
        const y = (canvas.height - imageHeight) / 1.30;
  
        // Dibujar un fondo en el lienzo
        ctx.fillStyle = '#2E3A4D';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        // Dibujar la imagen en el lienzo
        ctx.drawImage(image, x, y, imageWidth, imageHeight);
      }
    };
  
    // Cargar la imagen que deseas dibujar
    const image = document.createElement('img');
    image.src = `${logo}`; // Reemplaza con la ruta de tu imagen
  
    // animacion de transicion de la imagen
    image.onload = () => {        
      const animate = () => {
        position += 0.01; // Ajusta la velocidad de avance de la imagen
  
        drawImage(image, position);
  
        if (position < 1) {
          requestAnimationFrame(animate);
        }
      };
  
      animate();
    };

    const handleResize = () => {
      // Volver a dibujar la imagen cuando cambie el tamaño de la pantalla
      drawImage(image, position);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="hero mb-5">
        <video autoPlay loop muted >
          <source src={video} type="video/mp4" />
        </video>
        <canvas ref={canvasRef} className="canvas" />
      </div>
      <motion.div
        className="box infoServicios pb-5"
        ref={ref}        
        variants={boxVariant}
        initial="hidden"
        animate={control}
        style={styles}
      >  
        {desarrolloData.map((data, index) => (          
          <div className="mt-5" key={index}>
            {index % 2 === 0 ? (
              <TitleIzquierda titulo={data.titulo} scroll={inView}/>
            ) : (
              <TitleDerecha titulo={data.titulo} scroll={inView}/>
            )}
            <div className={`${data.style} mt-5`}>
              <Container className="pt-5 pb-5">
                <Row className="alturaServicios">
                  {data.texto}
                </Row>
              </Container>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
}
