import React, { useEffect } from "react";
import { Card, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Title from "../TitleDerecha";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function FundamentoAglobriq() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
    className="box Fundamento"
    ref={ref}
    variants={boxVariant}
    initial="hidden"
    animate={control}>
      <Title titulo="Fundamento y mecanismo de desarrollo del Aglomerante" scroll={inView}/>
      <Container className="pt-5 pb-5">
        <Row>
          <Card.Text className="text-justify">
            El objetivo de los aglomerantes consiste en asegurar que mejore la adhesión entre las partículas de la briqueta al momento de ser formadas, por lo cual, en el caso de AgloBriq se desarrollaron principios básicos para mejorar la carga superficial a nivel de partículas que permita una mejor adhesión entre ellas. Usar silicato de sodio favorece un mayor cantidad de gangas que se mezclan con el hierro.
          </Card.Text>
          <Card.Text className="text-justify">
            Se realizó una investigación para aumentar la carga superficial de las partículas con el uso de un aditivo, disminuyendo la cantidad de ganga para favorecer el contenido metálico en la briqueta final sin perder o mejorando sus propiedades físicas.
          </Card.Text>
        </Row>
      </Container>
    </motion.div>
  );
}