import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Container, Card, Form, Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios"; // Importa Axios

import TitleTabs from "../../components/Title";
import { carreraData } from "../../constants/carreraData";
import Loading from "../../components/Loading/Loading";

import "../../styles/components/Oferta.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Oferta({modoNoche}) {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormUnsubmitted, setIsFormUnsubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtroSelected, setIsOtroSelected] = useState(false);
  const [otraCarrera, setOtraCarrera] = useState("");

  const styles = {
    color: modoNoche ? "white" : "black",
  };

  const onChange = () => {
    console.log("Hubo un cambio")
  }

  const handleOtroChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOtroSelected(e.target.checked);

    // Limpiar el campo de texto cuando se deshabilite
    if (!e.target.checked) {
      setOtraCarrera("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Obtén los datos del formulario
    const formData = new FormData();
    formData.append("Nombre", e.target.Nombre.value);
    formData.append("Email", e.target.Email.value);
    formData.append("CV", e.target.CV.files[0]);    

    // Handle radio button selection
    const selectedCarrera = carreraData.find((data) => e.target[data.idCarrera].checked);
    if (selectedCarrera) {
      formData.append("Area", selectedCarrera.label);
    } else {
      formData.append("Area", e.target.otraCarrera.value);
    }    

    // Realiza la llamada a la API usando Axios
    try {
      setIsLoading(true);

      await axios.post(
        "https://mail-sites.msw.com.mx/api/Email",        
        formData,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'TokenSitio' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTGFib3JhdG9yaW8gTVNXIiwibmFtZWlkIjoiTGFib3JhdG9yaW8iLCJuYmYiOjE2OTY1MzQyNTcsImV4cCI6MTg1NDM4NzA1NywiaWF0IjoxNjk2NTM0MjU3fQ.-KSJOr6A8If_dOmonnCtl4SaZpNhtr8fZtqePyN1C5g'
          },
        },
      );

      const formulario = [
        {
          nombreCompleto: e.target.Nombre.value,
          correo: e.target.Email.value,
          cv: e.target.CV.files[0],
          carreraAfin: selectedCarrera?.label
        }
      ]

      // Maneja la respuesta de la API aquí
      console.log("Respuesta de la API:", formulario[0]);

      // Resetear los campos cuando se cumpla el try
      if (formRef.current) {
        formRef.current.reset();
      }

      // Establecer el estado de éxito del formulario
      setIsFormSubmitted(true);

      // Configurar un temporizador para restablecer el estado después de 5 segundos
      setTimeout(() => {
        setIsFormSubmitted(false);
      }, 5000);

    } catch (error) {
      // Maneja cualquier error que ocurra durante la llamada a la API aquí
      console.error("Error al llamar a la API:", error);

      // Establecer el estado de error del formulario
      setIsFormUnsubmitted(true);

      // Configurar un temporizador para restablecer el estado después de 5 segundos
      setTimeout(() => {
        setIsFormUnsubmitted(false);
      }, 5000);

    } finally {
      setIsLoading(false); // Ocultar el componente de loading después de try/catch
    }
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (    
    <>
      {isLoading && <Loading />}
      <div className="Oferta mt-5">
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          style={styles}
        >
          <Container>
            <Row>
              <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                <Row className="">
                  <TitleTabs titulo="¡Forma parte de nuestro Equipo de trabajo!"/>
                </Row>
                <Row className="texto">
                  <p>
                    Buscamos personas proactivas, que les guste aprender y colaborar en equipo, talento que se sume a nuestros proyectos a largo plazo, creando un impacto significativo en nuestros clientes, proveedores y comunidades locales.
                  </p>
                  <p>
                    En Laboratorio MSW valoramos el crecimiento y desarrollo profesional de nuestros colaboradores desde el inicio de su trayectoria, brindándote capacitaciones, prácticas en el mundo real y programas diseñados para adquirir experiencia práctica. Serás parte de un equipo especializado que te brindará apoyo y seguimiento personalizado.
                  </p>
                  <p>
                    ¡Envía tu solicitud ahora mismo y únete a nosotros en la realización de proyectos innovadores y desafiantes!
                  </p>
                </Row>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="p-4 fondoGris"
              >
                <Card.Body>
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <Form.Group className="mb-3" controlId="formBasicNombre">
                      <Form.Label className="text-white">
                        Nombre Completo
                      </Form.Label>
                      <Form.Control type="text" placeholder="Nombre Completo" name="Nombre"/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="text-white">Correo</Form.Label>
                      <Form.Control type="email" placeholder="Correo" name="Email"/>
                    </Form.Group>

                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label className="text-white">Adjunta tu CV</Form.Label>
                      <Form.Control type="file" name="CV"/>
                    </Form.Group>

                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label className="text-white">Carrera Afin</Form.Label>
                      {["radio"].map((type: any) => (
                        <div key={`${type}`} className="mb-3">
                          {carreraData.map((data: any) => (
                            <div key={data.idCarrera}>
                              <Form.Check
                                className="text-white"
                                label={data.label}
                                name="carreraAfin"
                                type={type}
                                id={data.idCarrera}
                                onChange={() => {
                                  setIsOtroSelected(false);
                                  setOtraCarrera(""); // Limpiar el campo de texto
                                }}
                              />
                            </div>
                          ))}
                          <div className="d-flex">
                            <Form.Check
                              className="text-white"
                              label="Otro"
                              type={type}
                              name="carreraAfin"
                              id="otroCarrera"
                              onChange={handleOtroChange}
                              checked={isOtroSelected}
                            />
                            <Form.Control
                              type="text"
                              className="ms-2"
                              placeholder="Otra carrera"
                              name="otraCarrera"
                              value={otraCarrera}
                              onChange={(e) => setOtraCarrera(e.target.value)}
                              disabled={!isOtroSelected}
                            />
                          </div>
                        </div>
                      ))}
                    </Form.Group>
                    <div>
                      <ReCAPTCHA
                        sitekey="6Lf6OQ4pAAAAABOSNVJ4NSmsOVlJXUfpcUMp731r"
                        onChange={onChange}
                        size="invisible"
                      />
                    </div> 
                    <div className="d-flex justify-content-end">
                      <Button className="botonColor" type="submit" size="lg">
                        Postularme
                      </Button>
                    </div>                              
                  </Form>                
                  {isFormSubmitted && (
                    <div className="alert alert-success mt-3">
                      ¡El formulario se ha enviado con éxito!
                    </div>
                  )}
                  {isFormUnsubmitted && (
                    <div className="alert alert-danger mt-3">
                      Falta completar el formulario
                    </div>
                  )}
                </Card.Body>
              </Col>
            </Row>
          </Container>        
        </motion.div>
      </div>
    </>    
  );
}
