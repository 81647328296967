import React,{ useEffect, useRef} from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Informacion from "../../components/Investigacion/InfoAgrlobriq";
import Propiedades from "../../components/Investigacion/PropiedadesAglobriq";
import Ventajas from "../../components/Investigacion/VentajasAglobriq";
import Fundamento from "../../components/Investigacion/FundamentoAglobriq";
import Producto from "../../components/Investigacion/ProductoAglobriq";

import hero from "../../assets/images/fondos/hero.jpg";
import logoAglobriq from "../../assets/images/Aglobriq.png";

import "../../styles/components/Aglobiq.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Info({modoNoche}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const control = useAnimation();
  const [ref, inView] = useInView();

  const styles = {
    backgroundColor: modoNoche ? "black" : "white",
    color: modoNoche ? "white" : "black",
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    let position = 0;
  
    const drawImage = (image, position) => {
      if (ctx && canvas) {
        // Configurar el tamaño del canvas
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        // Calcular las coordenadas y tamaño de la imagen
        const scale = Math.min(canvas.width / image.width, canvas.height / image.height) / 3;
        const imageWidth = image.width * scale;
        const imageHeight = image.height * scale;
        const x = position * (canvas.width - imageWidth) /1.05;
        const y = (canvas.height - imageHeight) / 1.22;
  
        // Dibujar un fondo en el lienzo
        ctx.fillStyle = '#2E3A4D';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        // Dibujar la imagen en el lienzo
        ctx.drawImage(image, x, y, imageWidth, imageHeight);
      }
    };
  
    // Cargar la imagen que deseas dibujar
    const image = document.createElement('img');
    image.src = `${logoAglobriq}`; // Reemplaza con la ruta de tu imagen
  
    // animacion de transicion de la imagen
    image.onload = () => {        
      const animate = () => {
        position += 0.01; // Ajusta la velocidad de avance de la imagen
  
        drawImage(image, position);
  
        if (position < 1) {
          requestAnimationFrame(animate);
        }
      };
  
      animate();
    };

    const handleResize = () => {
      // Volver a dibujar la imagen cuando cambie el tamaño de la pantalla
      drawImage(image, position);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <>
      <div className="heroAglobriq mb-5">
        <img src={hero} width="100%" alt="hero" />
        <canvas ref={canvasRef} className="canvas" />      
      </div>
      <motion.div
        className="box aglobiq text-center"
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
        style={styles}
      >      
        <Informacion />
        <Propiedades />
        <Ventajas />
        <Fundamento />
        <Producto />
      </motion.div>
    </>
  );
}
