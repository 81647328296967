import React, { useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Title from "../TitleDerecha";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function PropiedadesAglobriq() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  
  return (
    <motion.div
    className="box"
    ref={ref}
    variants={boxVariant}
    initial="hidden"
    animate={control}>
      <Title titulo="Propiedades Fisicoquímicas"scroll={inView} />
      <Container className="pt-5 pb-5">
        <Table responsive striped>
          <thead>
            <tr>
              <th>Concepto</th>
              <th>Criterio</th>
              <th>AgloBriq</th>
              <th>Silicato de sodio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Apariencia</td>
              <td>Relativo</td>
              <td>Rojizo</td>
              <td>Turbio</td>
            </tr>
            <tr>
              <td>Olor</td>
              <td>Relativo</td>
              <td>Sin olor</td>
              <td>Sin olor</td>
            </tr>
            <tr>
              <td>pH</td>
              <td>-</td>
              <td>10 - 12</td>
              <td>10 - 12</td>
            </tr>
            <tr>
              <td>Inflamabilidad</td>
              <td>Relativo</td>
              <td>No inflamable</td>
              <td>No imflamable</td>
            </tr>
            <tr>
              <td>Densidad Relativa</td>
              <td>
                Kg/m
                <sup>3</sup>
              </td>
              <td>1.675</td>
              <td>1.700</td>
            </tr>
            <tr>
              <td>Solubilidad</td>
              <td>Relativo</td>
              <td>Soluble en agua</td>
              <td>Soluble en agua</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </motion.div>
  );
}